// dependencies
import React from 'react';


// components
import Nav from '@sub-components/Nav';
import Footer from '@sub-components/Footer';


function Quote() {
    return (
        <div className='page'>
            <Nav/>
            <div className='content'>
                <div className='.banner'><br/><br/><br/><br/></div>
                <h1>Do You Want a Free Quote?</h1>
                <iframe title='title' className='quote' src="https://docs.google.com/forms/d/e/1FAIpQLSdYaH9p2m_LESFlbZ7gk11tGWs1f7j-7kzPMM5g9end_9AnjQ/viewform?embedded=true" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
            </div>
            <Footer/>
        </div>
    );
}


export default Quote;