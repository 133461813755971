// dependencies
import React from 'react';
import { Link } from 'react-router-dom';


// TODO: 404 page works on local host but not on github pages
function NotFound() {
    return (
        <div className="not-found-container">
            <h1>Oops! Page Not Found</h1>
            <p>We can't seem to find the page you're looking for.</p>
            <p>It might have been removed, had its name changed, or is temporarily unavailable.</p>
            <Link to="/" className="home-link">Go to Home</Link>
        </div>
    );
};

export default NotFound;