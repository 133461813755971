// dependencies
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';


// media
import logo from '@media/logos/logo-nobg.png';


function IndexNav() {
    const [isShrunk, setIsShrunk] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            setIsShrunk(currentScrollY > window.innerHeight * 0.1);
        };

        window.addEventListener('scroll', handleScroll, {passive: true});

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div>
            <nav id='index-nav' className='index-nav'>
                <div className={isShrunk ? 'logo-title-container-shrunk' : 'logo-title-container'}>
                    <Link to="/">
                        <img className={isShrunk ? 'logo-small' : 'logo'} src={logo} alt='Anozira Investments Logo' draggable='false'/>
                    </Link>
                    <h1 className={`banner-title ${isShrunk ? 'banner-title-hidden' : ''}`}>Business Consulting</h1>
                </div>
                <div className='link-container'>    
                    <Link className='link' to="/">Home</Link>
                    <Link className='link' to="/quote">Quote</Link>
                </div>
            </nav>
        </div>
    );
}

export default IndexNav;