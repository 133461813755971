// dependencies
import React from 'react';
import { Link } from 'react-router-dom';


// media
import logo from '@media/logos/logo-nobg.png';


function Nav() {
    return (
        <div>
            <nav id='index-nav' className='index-nav'>
                <div className='logo-container'>
                    <Link to="/"><img className='logo-small' src={logo} alt='Anozira Investments Logo' draggable='false'/></Link>
                </div>
                <div className='link-container'>    
                    <Link className='link' to="/">Home</Link>
                    <Link className='link' to="/quote">Quote</Link>
                </div>
            </nav>
        </div>
    );
};


export default Nav;