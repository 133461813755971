// dependancies
import React, { useEffect } from 'react';


// css
import '@css/stylesheet.css';


// media
import banner_video from '@media/index/investing-vid.mp4';
import our_story from '@media/index/our_story.jpeg';
import our_mission from '@media/index/our_mission.jpeg';
import residential_development from '@media/index/real-estate.jpeg';
import commercial_development from '@media/index/investing.jpeg';
import business from '@media/index/business.jpeg';


function Home() {
    useEffect(() => {
        const vid = document.getElementById('banner_video');
        if (vid) {
            vid.setAttribute('playsInline', '');
        }
    }, []);


    return (
        <div className='content'>
            <div>
                <video id='banner_video' autoPlay loop muted playsInline><source src={banner_video} type="video/mp4"/></video>
            </div>
            <div className='about-us'>
                <h2>About Us</h2>
                <div className='about'>
                    <div className='text-container'>
                        <img className='image' src={our_story} alt='Our Story' draggable='false'/>
                        <h3>Our Story</h3>
                        <p className='text'>
                            At Anozira Investments, we believe in the power of vision and the strength
                            of collaboration. Our journey began with a shared passion for transforming 
                            financial dreams into reality. Over the years, we have evolved, adapting 
                            to the ever-changing landscape of business consulting while staying true 
                            to our core values. Our story is one of dedication, innovation, and a 
                            relentless pursuit of excellence. With a team that brings diverse expertise 
                            and a commitment to personalized service, we have built a reputation for 
                            helping our clients navigate their unique financial paths. We understand 
                            that every client has a story, and we are here to listen, guide, and 
                            support you in achieving all your aspirations.
                        </p>
                    </div>
                    <div className='text-container'>
                        <img className='image' src={our_mission} alt='Our Mission' draggable='false'/>
                        <h3>Our Mission</h3>
                        <p className='text'>
                            t Anozira Investments, we are committed to helping you achieve your 
                            financial goals through personalized and strategic investment advice. 
                            Our team of experienced professionals brings a wealth of knowledge
                            and expertise to the table, ensuring that your investment portfolio 
                            is tailored to your unique needs and aspirations. We pride ourselves
                            on our client-centric approach, placing your interests at the 
                            forefront of everything we do. Whether you are an individual investor 
                            seeking to grow your wealth or a business looking to optimize your 
                            financial strategies, Anozira Investments is here to provide 
                            insightful guidance and innovative solutions. Our mission is to 
                            empower you with the tools and confidence to make informed investment 
                            decisions, fostering long-term financial success. Join us at Anozira 
                            Investments, where your future is our priority.
                        </p>
                    </div>
                </div>
            </div>
            <div className='dev-consulting'>
                <h2>Consulting</h2>
                <div className='dev-container'>
                    <div className='dev'>
                        <div className='text-container'>
                            <h3>Real Estate</h3>
                            <p className='text'>
                                At Anozira Investments, we specialize in providing expert real 
                                estate consulting services. Our team offers comprehensive market 
                                analysis, strategic planning, and personalized advice to help 
                                you make informed decisions. Whether you're buying, selling, or 
                                investing, we are here to guide you every step of the way, 
                                ensuring your real estate ventures are successful and rewarding.
                            </p> 
                        </div>
                        <img className='image' src={residential_development} alt='Residential Development' draggable='false'/>
                    </div>
                    <div className='com-dev'>
                        <div className='text-container'>
                            <h3>Business</h3>
                            <p className='text'>
                                Anozira Business Consulting offers tailored consulting services 
                                to help you achieve your business goals. Our experienced advisors 
                                provide personalized strategies, market insights, and continuous 
                                support to optimize your operations. Trust us to guide you 
                                through the complexities of business management, ensuring your 
                                company's future is secure and prosperous.
                            </p> 
                        </div>
                        <img className='image' src={business} alt='Commercial Development' draggable='false'/>
                    </div>
                    <div className='dev'>
                        <div className='text-container'>
                            <h3>Investment</h3>
                            <p className='text'>
                                Anozira Investments offers tailored investment consulting to help 
                                you achieve your financial goals. Our experienced advisors provide 
                                personalized strategies, market insights, and continuous support to 
                                optimize your portfolio. Trust us to guide you through the 
                                complexities of investing, ensuring your financial future is secure 
                                and prosperous.
                            </p> 
                        </div>
                        <img className='image' src={commercial_development} alt='Commercial Development' draggable='false'/>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default Home;