// dependencies
import React from 'react';
import { HashRouter as Router, Routes, Route, useLocation } from 'react-router-dom'


// css
import '@css/App.css';
import '@css/stylesheet.css';


// components
import Footer from '@sub-components/Footer';
import IndexNav from '@sub-components/IndexNav';
import Nav from '@sub-components/Nav';
import Home from '@pages/Home'
import Quote from '@pages/Quote';
import NotFound from '@pages/NotFound';



function App() {
    return (
        <div className='page'>
            <Router>
                <ConditionalNav />
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/quote' element={<Quote />} />
                    <Route path='*' element={<NotFound />} />
                </Routes>
                <Footer />
            </Router>
        </div>
    );
}


function ConditionalNav() {
    const location = useLocation();
    return location.pathname === '/' ? <IndexNav /> : <Nav />;
}


export default App;